import { default as completed_45shipmentsO0DzX0UNHUMeta } from "/home/forge/shipping-portal.sonnenglas.net/releases/20240424093142/pages/completed-shipments.vue?macro=true";
import { default as indexM5iu1d5PIWMeta } from "/home/forge/shipping-portal.sonnenglas.net/releases/20240424093142/pages/index.vue?macro=true";
import { default as inventoryDoYhxVHZswMeta } from "/home/forge/shipping-portal.sonnenglas.net/releases/20240424093142/pages/inventory.vue?macro=true";
import { default as loginGKxQsNyPrFMeta } from "/home/forge/shipping-portal.sonnenglas.net/releases/20240424093142/pages/login.vue?macro=true";
import { default as shipments5xrqmjhmE6Meta } from "/home/forge/shipping-portal.sonnenglas.net/releases/20240424093142/pages/shipments.vue?macro=true";
export default [
  {
    name: completed_45shipmentsO0DzX0UNHUMeta?.name ?? "completed-shipments",
    path: completed_45shipmentsO0DzX0UNHUMeta?.path ?? "/completed-shipments",
    meta: completed_45shipmentsO0DzX0UNHUMeta || {},
    alias: completed_45shipmentsO0DzX0UNHUMeta?.alias || [],
    redirect: completed_45shipmentsO0DzX0UNHUMeta?.redirect,
    component: () => import("/home/forge/shipping-portal.sonnenglas.net/releases/20240424093142/pages/completed-shipments.vue").then(m => m.default || m)
  },
  {
    name: indexM5iu1d5PIWMeta?.name ?? "index",
    path: indexM5iu1d5PIWMeta?.path ?? "/",
    meta: indexM5iu1d5PIWMeta || {},
    alias: indexM5iu1d5PIWMeta?.alias || [],
    redirect: indexM5iu1d5PIWMeta?.redirect,
    component: () => import("/home/forge/shipping-portal.sonnenglas.net/releases/20240424093142/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inventoryDoYhxVHZswMeta?.name ?? "inventory",
    path: inventoryDoYhxVHZswMeta?.path ?? "/inventory",
    meta: inventoryDoYhxVHZswMeta || {},
    alias: inventoryDoYhxVHZswMeta?.alias || [],
    redirect: inventoryDoYhxVHZswMeta?.redirect,
    component: () => import("/home/forge/shipping-portal.sonnenglas.net/releases/20240424093142/pages/inventory.vue").then(m => m.default || m)
  },
  {
    name: loginGKxQsNyPrFMeta?.name ?? "login",
    path: loginGKxQsNyPrFMeta?.path ?? "/login",
    meta: loginGKxQsNyPrFMeta || {},
    alias: loginGKxQsNyPrFMeta?.alias || [],
    redirect: loginGKxQsNyPrFMeta?.redirect,
    component: () => import("/home/forge/shipping-portal.sonnenglas.net/releases/20240424093142/pages/login.vue").then(m => m.default || m)
  },
  {
    name: shipments5xrqmjhmE6Meta?.name ?? "shipments",
    path: shipments5xrqmjhmE6Meta?.path ?? "/shipments",
    meta: shipments5xrqmjhmE6Meta || {},
    alias: shipments5xrqmjhmE6Meta?.alias || [],
    redirect: shipments5xrqmjhmE6Meta?.redirect,
    component: () => import("/home/forge/shipping-portal.sonnenglas.net/releases/20240424093142/pages/shipments.vue").then(m => m.default || m)
  }
]